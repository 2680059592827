import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Paths } from "../../../../constants/appConstants";
import { RootState } from "../../../../redux/reducers";
import { getPathBasedOnApplicationRoute } from "../../../../utils/application_routes";
import LogoutButtonComponent from "../logout-component";
import { SidebarWrapper } from "../styles";

type MenuObj = {
  id: number;
  option_name: string;
  option_value: string;
  name: string;
  path: string;
  iconClass: string;
};

type StateType = {
  isSubmenuVisible: boolean;
  menuItemData: MenuObj[];
  smartAgroMenuItem: MenuObj[];
};

const SakshamSidebar: React.FC = () => {
  const navigate = useNavigate()
  const { selectedApplication, country_App_BUs_Mapping_List } = useSelector(
    (state: RootState) => state.User
  );

  const [state, setState] = useState<StateType>({
    isSubmenuVisible: false,
    menuItemData: [],
    smartAgroMenuItem: [],
  });

  const [smartAgroMenuItems, setSmartAgroMenuItems] = useState<MenuObj[]>([]); // Added MenuObj[] as the initial state type

  const [selectedOptionValue, setSelectedOptionValue] = useState<string>(""); // Initialized with an empty string

  const handleMenuItemClick = (item: string, path: any) => {
    if (country_App_BUs_Mapping_List?.length > 1) {
      navigate(path);
    }
    setSelectedOptionValue(item);
  };

  const highlightSelecteditem = (optionValue: string) => {
    if (location.pathname !== Paths.Dashboard) {
      return optionValue === "Home" ? "inActivedot" : "activedot"
    }
    else {
      return optionValue === selectedOptionValue ? "activedot" : "inActivedot";
    }
  };
  useEffect(() => {
    if (location.pathname === Paths.Dashboard && country_App_BUs_Mapping_List?.length > 1) {
      setSmartAgroMenuItems([]); // Clear smartAgroMenuItems when returning to the dashboard if length is greater than 1
    } else if (selectedApplication?.app_details) {
      const newAppMenu: MenuObj[] = [
        {
          id: 2,
          option_name: selectedApplication.app_details.app_name,
          option_value: selectedApplication.app_details.app_code,
          name: selectedApplication.app_details.app_name,
          path: getPathBasedOnApplicationRoute(selectedApplication?.app_details?.app_code),
          iconClass: "icon-smartagro sidemenuIcon",
        },
      ];
      setSmartAgroMenuItems(newAppMenu);
    } else {
      setSmartAgroMenuItems([]);
    }
  }, [location.pathname, country_App_BUs_Mapping_List, selectedApplication]);

  const SIDEBAR_CONFIG_DATA = useMemo(() => {

    let result: MenuObj[] = [];
    let homearray: MenuObj[] = [
      {
        id: 1,
        option_name: "Home",
        option_value: "Home",
        name: "Home",
        path: Paths.Dashboard,
        iconClass: "icon-globe sidemenuIcon",
      },
    ];


    if (location.pathname === Paths.Dashboard && country_App_BUs_Mapping_List?.length > 1) {
      result = [...homearray];
    } else if (location.pathname === Paths.Dashboard && country_App_BUs_Mapping_List?.length === 1) {
      result = [...homearray, ...smartAgroMenuItems];
    } else {
      result = [...homearray, ...smartAgroMenuItems];
    }
    return result;
  }, [smartAgroMenuItems]);

  useEffect(() => {
    if (SIDEBAR_CONFIG_DATA.length > 0) {
      setSelectedOptionValue(SIDEBAR_CONFIG_DATA[0].option_value);
    }
  }, [SIDEBAR_CONFIG_DATA]);

  return (
    <>
      <SidebarWrapper>
        <Box className="sideMenu">
          <ul className="menuListMain">
            {SIDEBAR_CONFIG_DATA.length > 0 &&
              SIDEBAR_CONFIG_DATA.map((item, _index) => (
                <li key={item.id} className={`menuList`}>
                  <NavLink
                    key={item.id}
                    to={
                      item.path.toLowerCase() === Paths.CropWiseGrower
                        ? "#"
                        : `${item.path}`
                    }
                    state={item.option_value}
                    className={highlightSelecteditem(
                      item.option_value
                    )}
                    onClick={() => handleMenuItemClick(item.option_value, item.path)}
                    end
                  >
                    <Typography
                      component="span"
                      className={item.iconClass}
                    ></Typography>
                    <Typography component="p" className="menuItem">
                      {item.name}
                    </Typography>
                  </NavLink>
                </li>
              ))}
            <LogoutButtonComponent />
          </ul>
        </Box>
      </SidebarWrapper>
      <Outlet />
    </>
  );
};

export default SakshamSidebar;
