/**
 * Auth Root Router
 */

import React, { memo, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { app_Actions } from "../actions/appActions";
import Loader from "../components/Common/loader/Loader";
import Toasters from "../components/Common/Toaster";
import {
       isSuperAdmin,
       isValidUser,
       PAGE_TITLE,
       Paths,
       ROUTE_PREFIX
} from "../constants/appConstants";
import { RootState } from "../redux/reducers";
import { getAccessedRouter } from "./auth-utils";
import HeaderSideWrapper from "./HeaderWrapper";

const AuthRootRouter: React.FC = () => {
       const { UserDetails } = useSelector((state: RootState) => state.User);
       const countryAppBUsMappingList = useSelector((state: RootState) => state.User.country_App_BUs_Mapping_List);
       const { pathname } = useLocation();
       const navigate = useNavigate();
       const dispatch = useDispatch();

       const [accessedRouter, setAccessedRouter] = useState<any>([]);
       useEffect(() => {
              // Get the Accessed Routes based on the Role type
              const ACCESSED_AUTH_ROUTER =
                     getAccessedRouter(
                            isSuperAdmin(UserDetails?.role_type_details?.role_type_name), countryAppBUsMappingList
                     ) ?? [];
              setAccessedRouter(ACCESSED_AUTH_ROUTER);
       }, []);

       /**
        * Route Location listener for
        * Updating page title
        * Redirect to Dashboard based on user login role
        * Hide and show the Smart agro and Back office Sidebar
        */
       useEffect(() => {
              //Update document title based on the route path

              let pageTitle: any = PAGE_TITLE;
              document.title = pageTitle;

              /**
               * Redirect to MFE Dashboard
               */
              if (
                     pathname === Paths.Login &&
                     isValidUser(UserDetails?.role_type_details.role_type_name)

              ) {
                     navigate(Paths.Dashboard);
              }

              /**
               * Hide and show the side menu bar based on the application
               */
              if (
                     pathname.toLowerCase().includes(ROUTE_PREFIX.SMART_AGRO.toLowerCase())
                     || pathname.toLowerCase().includes(ROUTE_PREFIX.RETAILER_CONNECT.toLowerCase()) || pathname.toLowerCase().includes(ROUTE_PREFIX.CROP_WISE_GROWER.toLowerCase()) ||
                     (pathname === Paths.Dashboard && isValidUser(UserDetails?.role_type_details.role_type_name)
                     )
              ) {
                     dispatch(app_Actions.setSmartAgroAppLayout(true));
              } else if (
                     pathname
                            .toLowerCase()
                            .includes(ROUTE_PREFIX.Global_Setting.toLowerCase()) &&
                     isSuperAdmin(UserDetails?.role_type_details.role_type_name)

              ) {
                     dispatch(app_Actions.setSmartAgroAppLayout(false));
              }
       }, [pathname]);

       return (
              <>
                     <Toasters />
                     <Loader />
                     <Routes>
                            {accessedRouter.length > 0 &&
                                   accessedRouter.map((route: any, _routeIndex: any) => {
                                          return (
                                                 <Route
                                                        key={`router-${_routeIndex}-${route?.moduleName}}`}
                                                        path={route?.path}
                                                        element={
                                                               <Suspense fallback={<Loader />}>
                                                                      <HeaderSideWrapper>{route.element}</HeaderSideWrapper>
                                                               </Suspense>
                                                        }
                                                 />
                                          );
                                   })}
                     </Routes>
              </>
       );
};

export default memo(AuthRootRouter);
