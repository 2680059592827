import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Menu, MenuItem, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { user_actions } from "../../../../actions/userActions";
import { Paths } from "../../../../constants/appConstants";
import { smart_agro_colors as colors } from "../../../../theme";
import { getPathBasedOnApplicationRoute } from "../../../../utils/application_routes";
import {
  HeaderDropdownMenu,
  useStyles,
} from "./SmartAgroHeaderDropdown.styles";

type MenuObj = {
  id: number;
  option_name: string;
  option_value: number;
  bu_code: string;
  portfolio_enable: boolean;
  portfolio_code: string;
  portfolio_id: number | string;
  userRoleControls: any;
};

interface IProps {
  optionsData: Array<MenuObj>;
  title: string;
  subTitle: string;
  optionTitle: string;
  icon_name: string;
  selectedOption: {
    selectedname: string | undefined;
    selectedValue: string | number | undefined;
  };
  countryAppBUsMappingList: any;
  selectedApplication: any;
}

const BUHeaderDropdown: React.FC<IProps> = ({
  optionsData,
  title,
  subTitle,
  optionTitle,
  icon_name,
  selectedOption,
  countryAppBUsMappingList,
  selectedApplication,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    const selectBU = optionsData
      .filter((val, idx) => idx === index)
      .map((bu) => bu)[0];
    dispatch(
      user_actions.setSelectedBusinessUnit({
        selectedBusinessUnit: {
          bu_id: selectBU.option_value,
          bu_name: selectBU.option_name,
          user_bu_id: selectBU.option_value,
          bu_code: selectBU?.bu_code,
          portfolio_enable: selectBU?.portfolio_enable,
          portfolio_code: selectBU?.portfolio_code,
          portfolio_id: selectBU?.portfolio_id,
          userRoleControls: selectBU.userRoleControls,
        },
      })
    );
    //set selected business unit in local storage
    localStorage.setItem("selectedBusinessUnit", JSON.stringify(selectBU));
    navigate(
      getPathBasedOnApplicationRoute(selectedApplication?.app_details?.app_code)
    );
    setAnchorEl(null);
    //realod page after changing BU redirect to home
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (
    location.pathname === Paths.Dashboard &&
    countryAppBUsMappingList.length > 1
  ) {
    return null; // Don't render anything if on the location dashboard
  }
  return (
    <HeaderDropdownMenu>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickListItem}
      >
        <Box>
          <Avatar>
            <Typography component={"span"} className={icon_name}></Typography>
          </Avatar>
        </Box>
        <Box>
          <Typography fontWeight={600} color={colors.white}>
            {title}
          </Typography>
          <Typography color={colors.white} fontWeight={400}>
            {subTitle}
          </Typography>
        </Box>
        {optionsData.length > 1 && <KeyboardArrowDownIcon />}
      </Stack>
      {optionsData.length > 1 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={`${classes.headerDropDownMenu} drop-down-menu`}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography variant="subtitle1">{optionTitle}</Typography>
          {optionsData.map((option, index) => (
            <MenuItem
              key={`${option.option_name}-${index}`}
              // anchorEl={anchorEl}
              selected={option.option_value === selectedOption.selectedValue}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <Avatar>
                <Typography
                  fontSize={10}
                  color={colors.webgrayLight}
                  fontWeight={500}
                >
                  {option.option_name.slice(0, 2).toUpperCase()}
                </Typography>
              </Avatar>
              <Typography fontWeight={600} fontSize={14} color={colors.webgray}>
                {option.option_name}
              </Typography>
              {option.option_value === selectedOption.selectedValue && (
                <Typography
                  component="span"
                  m={0.5}
                  bgcolor={colors.white}
                  color={colors.mainGreen}
                  className="currrent-option"
                  fontSize={12}
                >
                  I'm here
                </Typography>
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </HeaderDropdownMenu>
  );
};

export default BUHeaderDropdown;
