/**
 * Logout Component
 */

import { useMsal } from "@azure/msal-react";
import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_actions } from "../../../../actions/userActions";
import { Paths } from "../../../../constants/appConstants";
import { TriggerToastMessage } from "../../../../utils/toastTrigger";
import { SidebarWrapper } from "../styles";

const LogoutButtonComponent: React.FC = () => {
  const storeData = useSelector((state: any) => state);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const currentAccount = storeData.User.HomeAccountId;

  const handleLogout = () => {
    instance
      .logoutPopup({
        account: instance.getAccountByHomeId(currentAccount),
        mainWindowRedirectUri: Paths.Login,
        postLogoutRedirectUri: Paths.Login,
      })
      .then((res) => {
        dispatch(user_actions.logout());
        // get last selected BU before clear storage
                const getSelectedBU = localStorage.getItem("selectedBusinessUnit") ?? "";
        const parseSelectedBU = JSON.parse(getSelectedBU);
        // clear the session and local storage of the user
        localStorage.clear();
        sessionStorage.clear();
        // set last selected BU in local storage after clear
        localStorage.setItem("selectedBusinessUnit", JSON.stringify(parseSelectedBU));
        window.location.href = Paths.Login;
      })
      .catch((err) => {
        TriggerToastMessage(`${err}`, "error");
      });
  };

  return (
    <SidebarWrapper>
      <ul className="menuListMain">
                                <li className="menuList logoutMain" onClick={() => handleLogout()} data-testid='handle-logout'
                                >
          <Typography
            component="span"
            className="icon-logout sidemenuIcon"
          ></Typography>
          <Typography component="p" className="menuItem">
            Logout
          </Typography>
        </li>
      </ul>
    </SidebarWrapper>
  );
};
export default LogoutButtonComponent;
