import {
  IBusinessUnitsObj,
  ISelectedBUsObj,
  ISelectedCountry,
} from "../redux/reducers/interface-helpers";

class UserActions {
  // constants here (Action Constants are always lowercase)
  Constants = {
    LoginSuccess: "user/login/success",
    LoggingIn: "user/logging/in",
    UserNotAllowed: "user/login/notAllowed",
    LoggingInCompleted: "user/logging/in/completed",
    LogoutSuccess: "user/logout/success",
    ChangeProject: "user/change/project",
    ChangeTheme: "user/change/theme",
    SetUserDetails: "user/setUserDetails",
    SetAccessToken: "user/setAccessToken",
    SetTCAccpted: "user/SetTCAccpted",
    SetSelectedCountry: "user/SetSelectedCountry",
    SetHomeAccountId: "user/SetHomeAccountId",
    SetSelectedBusinessUnit:"user/SetSelectedBusinessUnit",

  };

  changeProject = (projectId: number) => ({
    type: this.Constants.ChangeProject,
    payload: projectId,
  });
  changeTheme = (theme: string) => ({
    type: this.Constants.ChangeTheme,
    payload: theme,
  });
  loginSuccess = (userInfo: any) => ({
    type: this.Constants.LoginSuccess,
    payload: userInfo,
  });
  logout = () => ({
    type: this.Constants.LogoutSuccess,
  });
  setUserDetails = (payload: object) => ({
    type: this.Constants.SetUserDetails,
    payload: payload,
  });
  setAccessToken = (payload: string) => ({
    type: this.Constants.SetAccessToken,
    payload: payload,
  });
  setTCAccepted = (payload: boolean) => ({
    type: this.Constants.SetTCAccpted,
    payload: payload,
  });

  setSelectedCountry = (payload: {
    selectedCountryDetails: ISelectedCountry | null;
    BusinessUnits: Array<IBusinessUnitsObj> | null;
    selectedBusinessUnit: ISelectedBUsObj | null;
    country_app_bus_mapping_list: any;
    selectedApplication: any;
  }) => ({
    type: this.Constants.SetSelectedCountry,
    payload: payload,
  });

  setSelectedBusinessUnit = (payload:{
    selectedBusinessUnit: ISelectedBUsObj | null;
  })=> ({
    type: this.Constants.SetSelectedBusinessUnit,
    payload: payload,
  });
  
  setHomeAccountId = (payload: string) => ({
    type: this.Constants.SetHomeAccountId,
    payload: payload,
  });
}
export let user_actions = new UserActions();
