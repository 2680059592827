import React from "react";
import { Navigate } from "react-router";
import { Paths } from "../constants/appConstants";
//UnAuth Screens

const LoginScreen = React.lazy(() => import("../components/login"));
const PolicyAgreement = React.lazy(
  () => import("../components/PolicyAgreement/PolicyAgreement")
);
const CountrySelection = React.lazy(
  () => import("../components/countrySelection/CountrySelection")
);
const TermsAndConditions = React.lazy(
  () => import("../components/termsandconditions/termsandconditions")
);
const TroubleLogin = React.lazy(() => import("../components/trouble-login"));

//Auth Screens

const CountryApplication = React.lazy(
  () => import("../components/SuperAdmin/country/countryApplication")
);
const CountryList = React.lazy(
  () => import("../components/SuperAdmin/country")
);
const Application = React.lazy(
  () => import("../components/SuperAdmin/Apps/Apps")
);
const Users = React.lazy(() => import("../components/SuperAdmin/users/users"));
const UserAppRoles = React.lazy(
  () => import("../components/SuperAdmin/users/UserAppRoles")
);
const BusinessUnits = React.lazy(
  () => import("../components/SuperAdmin/BUs/BUsComponent")
);
const PortFolios = React.lazy(
  () => import("../components/SuperAdmin/PortFolios/PortFoliosComponent")
);
const HelpSupport = React.lazy(
  () => import("../components/SuperAdmin/HelpSupport/HelpSupport")
);
const MFEDashboard = React.lazy(() => import("../components/mfe"));

const PageNotFound = React.lazy(() => import("../components/404"));
const Dashboard = React.lazy(() => import("../components/dashboard"));

export const AUTH_CONFIG_DATA = [
  {
    id: 1,
    moduleName: "RootComponent",
    element: <Navigate to={Paths.Country} />,
    path: Paths.EntryPath,
  },
  {
    id: 2,
    moduleName: "Country",
    element: <CountryList />,
    path: Paths.Country,
  },
  {
    id: 3,
    moduleName: "CounytryApplication",
    element: <CountryApplication />,
    path: Paths.CountryApplication,
  },
  {
    id: 4,
    moduleName: "Application",
    element: <Application />,
    path: Paths.Apps,
  },
  {
    id: 5,
    moduleName: "Users",
    element: <Users />,
    path: Paths.Users,
  },
  {
    id: 6,
    moduleName: "UserAppRoles",
    element: <UserAppRoles />,
    path: Paths.UserAppRoles,
  },
  {
    id: 7,
    moduleName: "BusinessUnits",
    element: <BusinessUnits />,
    path: Paths.BUs,
  },
  {
    id: 8,
    moduleName: "HelpSupport",
    element: <HelpSupport />,
    path: Paths.HelpSupport,
  },
  {
    id: 9,
    moduleName: "PortFolios",
    element: <PortFolios />,
    path: Paths.Portfolios,
  },
  {
    id: 10,
    moduleName: "Dashboard",
    element: <Dashboard />,
    path: Paths.Dashboard,
  },

  {
    id: 11,
    moduleName: "MFEDashboard",
    element: <MFEDashboard />,
    path: `${Paths.RetailerConnect}/*`,
  },

  {
    id: 12,
    moduleName: "PageNotFound",
    element: <PageNotFound />,
    path: Paths.Notfound,
  },
  {
    id: 13,
    moduleName: "MFEDashboard",
    element: <MFEDashboard />,
    path: `${Paths.CropWiseGrower}/*`,
  },
  {
    id: 14,
    moduleName: "MFEDashboard",
    element: <MFEDashboard />,
    path: `${Paths.SmartAgroLite}/*`,
  },
];

export const UN_AUTH_CONFIG_DATA = [
  {
    id: 1,
    moduleName: "Login",
    element: <LoginScreen />,
    path: Paths.Login,
  },
  {
    id: 2,
    moduleName: "TroubleLogin",
    element: <TroubleLogin />,
    path: Paths.TroubleLogin,
  },
  {
    id: 3,
    moduleName: "TermsAndConditions",
    element: <TermsAndConditions />,
    path: Paths.TermsAndConditions,
  },
  {
    id: 4,
    moduleName: "CountrySelection",
    element: <CountrySelection />,
    path: Paths.CountrySelection,
  },
  {
    id: 5,
    moduleName: "PolicyAgreement",
    element: <PolicyAgreement />,
    path: Paths.PolicyAgreement,
  },
  {
    id: 6,
    moduleName: "PageNotFound",
    element: <Navigate to={Paths.Login} />,
    path: Paths.Notfound,
  },
];


export const getAccessedRouter = (isSuperAdmin: boolean, countryAppBUsMappingList: any[]) => {
  if (isSuperAdmin) {
    // Return the full AUTH_CONFIG_DATA if the user is a super admin
    return AUTH_CONFIG_DATA;
  } else {
    const baseRouterList = countryAppBUsMappingList.length === 1 ? AUTH_CONFIG_DATA.slice(-4) : AUTH_CONFIG_DATA.slice(-5);
    const dashboardElement = countryAppBUsMappingList.length === 1 ? <MFEDashboard /> : <Dashboard />;
    const dashboardModuleName = "Dashboard";
    return [...baseRouterList, { id: 9, moduleName: dashboardModuleName, element: dashboardElement, path: Paths.Dashboard }];
  }
};
