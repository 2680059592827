import React from "react";
import { useSelector } from "react-redux";
import BackOfficeHeader from "../components/Common/header/back-office-header/BackOfficeHeader";
import SmartAgroHeader from "../components/Common/header/smart-agro-header/SmartAgroHeader";
import BackOfficeSidebar from "../components/Common/sidebar/back-office-sidebar/BackOfficeSidebar";
import SmartAgroSidebar from "../components/Common/sidebar/smart-agro-sidebar/SmartAgroSidebar";
import { RootState } from "../redux/reducers";

interface IProps {
          children: JSX.Element;
}

const HeaderSideBarWrapper: React.FC<IProps> = ({ children }) => {
          const { setSmartAgroAppLayout } = useSelector(
                    (state: RootState) => state.App
          );
          return (
                    <>
                              {setSmartAgroAppLayout ? (
                                        <>
                                                  <SmartAgroHeader />
                                                  <SmartAgroSidebar />
                                        </>
                              ) : (
                                        <>
                                                  <BackOfficeHeader />
                                                  <BackOfficeSidebar />
                                        </>
                              )}
                              {children}
                    </>
          );
};

export default HeaderSideBarWrapper;
