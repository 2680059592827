
import { Avatar, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { smart_agro_colors as colors } from "../../../../theme";
import {
       HeaderDropdownMenu,
} from "./SmartAgroHeaderDropdown.styles";
type MenuObj = {
  id: number;
  option_name: string;
  option_value: string | number;
};

interface IProps {
       title: string;
       subTitle: string;
       icon_name: string;
}

const HeaderDropdown: React.FC<IProps> = ({
       title,
       subTitle,
       icon_name,
}) => {
       const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
       const open = Boolean(anchorEl);
       const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget);
       };

       return (
              <HeaderDropdownMenu>
                     <Stack
                            direction="row"
                            alignItems="center"
                            gap={2}
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickListItem}
                            data-testid={'dropdown-header'}
                     >
                            <Box>
                                   <Avatar>
                                          <Typography component={"span"} className={icon_name}></Typography>
                                   </Avatar>
                            </Box>
                            <Box>
                                   <Typography fontWeight={600} color={colors.white}>
                                          {title}
                                   </Typography>
                                   <Typography color={colors.white} fontWeight={400}>
                                          {subTitle}
                                   </Typography>
                            </Box>
                            {/* <ArrowForwardIosIcon /> */}
                     </Stack>
              </HeaderDropdownMenu>
       );
};

export default HeaderDropdown;
