import { RedirectRequest } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.AZURE?.appId,
    authority: process.env?.AZURE?.authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env?.AZURE?.redirectURI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
   scopes: ['User.Read', 'openid']
  };

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me'
  };
