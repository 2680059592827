import _ from "../../utils/lodash";
import { app_Actions } from "../../actions/appActions";
import { user_actions } from "../../actions/userActions";
import { IAction } from "./interface-helpers";

export type ILoaderState = {
       showLoader: boolean;
};

const initialState = {
       showLoader: false,
};

export const loaderReducer = (

       state: ILoaderState = initialState,
       { type, payload }: IAction,
): ILoaderState => {
       switch (type) {
              case app_Actions.Constants.isLoading: {
                     return _.extend({}, state, { showLoader: payload });
              }
              case user_actions.Constants.LogoutSuccess: {
                     return _.extend({}, initialState);
              }
              default: {
                     return state;
              }
       }
};

export default loaderReducer;
